import { gtag, observeFirstViewportIntersection, ready } from "#js/components/utils"

function sendPromotionEvent(element, eventName) {
  gtag("event", eventName, {
    items: [{
      item_id: element.dataset.gtmItemId,
      item_name: element.dataset.gtmItemName,
      item_brand: element.dataset.gtmItemBrand,
      item_category: element.dataset.gtmItemCategory,
    }],
    promotion_id: element.dataset.gtmPromotionId,
    promotion_name: element.dataset.gtmPromotionName,
    creative_name: element.dataset.gtmCreativeName,
    creative_slot: element.dataset.gtmCreativeSlot,
  })
}

function sendPromotions(elements, viewEventName, selectEventName) {
  elements.forEach((element) => {
    observeFirstViewportIntersection(() => {
      sendPromotionEvent(element, viewEventName)
    }, element)

    element.addEventListener("click", () => {
      sendPromotionEvent(element, selectEventName)
    })
  })
}

export function sendTalkPromotions() {
  const featuredSeriesElement = document.querySelector('[data-gtm="featured_series"]')
  if (featuredSeriesElement) {
    sendPromotions(
      [featuredSeriesElement],
      "view_promotion_featured_series",
      "select_promotion_featured_series",
    )
  }

  const upcomingSeriesElements = document.querySelectorAll(
    '[data-gtm="upcoming_series"]',
  )
  sendPromotions(
    upcomingSeriesElements,
    "view_promotion_upcoming_series",
    "select_promotion_upcoming_series",
  )
}

ready(() => {
  sendTalkPromotions()
})
